import React from "react";
import styles from "./capabilities.module.scss";

import auth0 from "./images/1.png";
import okta from "./images/2.png";
import azureAD from "./images/3.webp";
import azureADB2C from "./images/Entral External ID.png";
import forgerock from "./images/ForgeRock(1)wefj.png";

import sailPoint from "./images/6.png";
import saviynt from "./images/7.png";
import oracle from "./images/8.png";
import oneIdentity from "./images/one.png";
import pingIdentity from "./images/10.png";

import cyberArk from "./images/Cyberark.png";
import delinea from "./images/Delinea.png";
import beyondTrust from "./images/13.png";
import azurePIM from "./images/EntraPIM.png";
import ibm from "./images/IBM.png";

import { NavLink } from "react-router-dom";

const Capabilities = () => {
  return (
    <div className={styles.container}>
      <div className={styles.row1Container}>
        <NavLink
          to="/capabilities?companyName=saviynt&companyNumber=7"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={saviynt} alt="saviynt" />
        </NavLink>
        <NavLink
          reloadDocument
          to="/capabilities?companyName=sailpoint&companyNumber=6"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={sailPoint} alt="sailPoint" />
        </NavLink>

        <NavLink
          to="/capabilities?companyName=oracle&companyNumber=8"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={oracle} alt="oracle" />
        </NavLink>
        <NavLink
          to="/capabilities?companyName=oneidentity&companyNumber=9"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={oneIdentity} alt="oneIdentity" />
        </NavLink>
        <NavLink
          to="/capabilities?companyName=pingidentity&companyNumber=10"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={pingIdentity} alt="pingIdentity" />
        </NavLink>
      </div>

      <div className={styles.row2Container}>
        <NavLink
          to="/capabilities?companyName=cyberark&companyNumber=11"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={cyberArk} alt="cyberArk" />
        </NavLink>
        <NavLink
          to="/capabilities?companyName=delinea&companyNumber=12"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={delinea} alt="delinea" />
        </NavLink>
        <NavLink
          to="/capabilities?companyName=beyondtrust&companyNumber=13"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={beyondTrust} alt="beyondTrust" />
        </NavLink>
        <NavLink
          to="/capabilities?companyName=azurepim&companyNumber=14"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={azurePIM} alt="azurePIM" />
        </NavLink>
        <NavLink
          to="/capabilities?companyName=ibm&companyNumber=15"
          className={`${styles.imageContainer} ${styles.ibm}`}
        >
          <img className={styles.image} src={ibm} alt="ibm" />
        </NavLink>
      </div>
      <div className={styles.row3Container}>
      <NavLink
          to="/capabilities?companyName=azuread&companyNumber=3"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={azureAD} alt="azureAD" />
        </NavLink>
        <NavLink
          to="/capabilities?companyName=auth0&companyNumber=2"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={auth0} alt="auth0" />
        </NavLink>{" "}
        <NavLink
          to="/capabilities?companyName=okta&companyNumber=1"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={okta} alt="okta" />
        </NavLink>
       
        <NavLink
          to="/capabilities?companyName=azureadb2c&companyNumber=4"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={azureADB2C} alt="azureADB2C" />
        </NavLink>
        <NavLink
          to="/capabilities?companyName=forgerock&companyNumber=5"
          className={styles.imageContainer}
        >
          <img className={styles.image} src={forgerock} alt="forgerock" />
        </NavLink>
      </div>
    </div>
  );
};

export default Capabilities;
