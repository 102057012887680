import React, { useEffect, useRef, useState } from 'react';
import styles from "./header.module.scss";

import websiteLogo from "../../../assets/images/LogoNew-Color.webp";
import Logo from '../../../assets/images/LogoNew-Color.webp'
import Services from './services/Services';
import Solutions from './solutions/Solutions';
import Capabilities from './capabilities/Capabilities';
import Companies from './companies/Companies';
import Resources from './resources/Resources';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const responsiveContainer = useRef(null);
    const handleContactClick = () => {
        window.scrollTo(0, document.body.scrollHeight);
    };

    const [responsiveOpen, setResponsiveOpen] = useState(false);
    const toggleResponsive = () => {
        setResponsiveOpen(responsiveOpen => !responsiveOpen);
    };
    useEffect(() => {
        if (!responsiveOpen) {
            responsiveContainer.current.style.height = 0;
            document.querySelector(".line1").style.transform = "translateY(-8px) rotate(0)";
            document.querySelector(".line2").style.opacity = 1;
            document.querySelector(".line3").style.transform = "translateY(8px) rotate(0)";
        } else {
            responsiveContainer.current.style.height = "300px";
            document.querySelector(".line1").style.transform = "translateY(-8px) rotate(35deg)";
            document.querySelector(".line2").style.opacity = 0;
            document.querySelector(".line3").style.transform = "translateY(8px) rotate(-35deg)";
        }
        
    }, [responsiveOpen]);

    const [accordionClick, setAccordionClick] = useState({
        services: false,
        solutions: false,
        company: false,
        resources: false,
    });

    const handleAccordionClick = (name) => {
        if (name === "services") {
            setAccordionClick({ ...accordionClick, services: !accordionClick.services });
        } else if (name === "solutions") {
            setAccordionClick({ ...accordionClick, solutions: !accordionClick.solutions });
        } else if (name === "company") {
            setAccordionClick({ ...accordionClick, company: !accordionClick.company });
        } else if (name === "resources") {
            setAccordionClick({ ...accordionClick, resources: !accordionClick.resources });
        }
        
    }; 
    
    useEffect(() => {
        // console.log(accordionClick);
        if (accordionClick.services === true) {
            document.querySelector(".servicesAccordionSign").style.transform = "rotateZ(45deg)";
            document.querySelector(".servicesNavLinksContainer").style.height = "160px";
        } else {
            document.querySelector(".servicesAccordionSign").style.transform = "rotateZ(0)";
            document.querySelector(".servicesNavLinksContainer").style.height = "0";
        }

        if (accordionClick.solutions === true) {
            document.querySelector(".solutionsAccordionSign").style.transform = "rotateZ(45deg)";
            document.querySelector(".solutionsNavLinksContainer").style.height = "108px";
        } else {
            document.querySelector(".solutionsAccordionSign").style.transform = "rotateZ(0)";
            document.querySelector(".solutionsNavLinksContainer").style.height = "0";
        }

        if (accordionClick.company === true) {
            document.querySelector(".companyAccordionSign").style.transform = "rotateZ(45deg)";
            document.querySelector(".companyNavLinksContainer").style.height = "155px";
        } else {
            document.querySelector(".companyAccordionSign").style.transform = "rotateZ(0)";
            document.querySelector(".companyNavLinksContainer").style.height = "0";
        }

        if (accordionClick.resources === true) {
            document.querySelector(".resourcesAccordionSign").style.transform = "rotateZ(45deg)";
            document.querySelector(".resourcesNavLinksContainer").style.height = "100px";
        } else {
            document.querySelector(".resourcesAccordionSign").style.transform = "rotateZ(0)";
            document.querySelector(".resourcesNavLinksContainer").style.height = "0";
        }
    }, [accordionClick]);
    const section3Image = websiteLogo; // Set the default image source
    const [imageSrc, setImageSrc] = useState(section3Image);
  
    // Function to change the image source based on screen width
    const changeImageSource = () => {
      if (window.innerWidth >= 600) {
        // Change the image source to a new image when the screen width is 600 pixels or more
        setImageSrc(section3Image);
      } else {
        // Use the default image source when the screen width is less than 600 pixels
        setImageSrc(Logo);
      }
    };
  
    // Add an event listener to the window's resize event
    useEffect(() => {
      window.addEventListener("resize", changeImageSource);
  
      // Call the changeImageSource() function initially to set the image source based on the initial screen width
      changeImageSource();
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("resize", changeImageSource);
      };
    }, []);
    return (
        <div className={styles.container}>
            <div className={styles.row1}>
                <NavLink className={styles.headerImageContainer}  to="/home">
                    <img className={styles.headerImage} src={imageSrc}  style={{width:"225px"}}  alt="SharpITS" />
                </NavLink>
                <div className={styles.navContainer}>
                    <div className={`${styles.pageLinkContainer}`}>
                        Services
                        <div className={`${styles.dropDownContainer}`}>
                            <Services />
                        </div>
                    </div>
                    <div className={`${styles.pageLinkContainer}`}>
                        Solutions
                        <div className={`${styles.dropDownContainer}`}><Solutions /></div>
                    </div>
                    <div className={`${styles.pageLinkContainer}`}>
                        <NavLink className={`${styles.capabilitiesNavLink}`}  to="/capabilities">Capabilities</NavLink>
                        <div className={`${styles.dropDownContainer} ${styles.capabilitiesContainer}`}>
                            <Capabilities />
                        </div>
                    </div>
                    <div className={`${styles.pageLinkContainer}`}>
                        Company
                        <div className={`${styles.dropDownContainer} ${styles.companiesContainer}`}>
                            <Companies />
                        </div>
                    </div>
                    <div className={`${styles.pageLinkContainer}`}>
                        Resources
                        <div className={`${styles.dropDownContainer} ${styles.resourcesContainer}`}>
                            <Resources />
                        </div>
                    </div>

                    <NavLink  to="/contact-us" className={`${styles.pageLinkContainer} ${styles.contact}`}>CONTACT</NavLink>

                    <div onClick={toggleResponsive} className={styles.dropDownIconContainer}>
                        <div className={`${styles.line} ${styles.line1} line1`}></div>
                        <div className={`${styles.line} ${styles.line2} line2`}></div>
                        <div className={`${styles.line} ${styles.line3} line3`}></div>
                    </div>
                </div>
            </div>
            <div ref={responsiveContainer} className={styles.responsiveContainer}>
                <div className={styles.responsiveInnerContainer}>
                    <div onClick={() => handleAccordionClick("services")} className={`${styles.servicesLink} servicesLink`}>
                        <div className={styles.servicesText}>Services</div>
                        <div className={`${styles.servicesAccordionSign} servicesAccordionSign`}>+</div>
                    </div>
                    <div className={`${styles.servicesNavLinks} ${styles.navLinksContainer} servicesNavLinksContainer`}>
                    <NavLink  onClick={toggleResponsive}  to="/services/iam-modernization-services" className={styles.subNavLink}>IAM Modernization Services</NavLink>
                        <NavLink  onClick={toggleResponsive}  to="/services/iam-professional-services" className={styles.subNavLink}>Professional Services</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/services/iam-advisory-and-assessment-services" className={styles.subNavLink}>Advisory and Assessment</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/services/iam-managed-services" className={styles.subNavLink}>Managed Services</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/services/staff-augementation" className={styles.subNavLink}>Staff Augmentation</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/services/v-ciso" className={styles.subNavLink}>vCISO</NavLink>
                    </div>

                    <div onClick={() => handleAccordionClick("solutions")} className={`${styles.solutionsLink} solutionsLink`}>
                        <div className={styles.solutionsText}>Solutions</div>
                        <div className={`${styles.solutionsAccordionSign} solutionsAccordionSign`}>+</div>
                    </div>
                    <div className={`${styles.solutionsNavLinks} ${styles.navLinksContainer} solutionsNavLinksContainer`}>
                        <NavLink onClick={toggleResponsive}   to="/solutions/identity-governance-and-administration" className={styles.subNavLink}>Identity Governance and Administration</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/solutions/access-management" className={styles.subNavLink}>Enterprise Access Management</NavLink>

                        <NavLink onClick={toggleResponsive}   to="/solutions/privileged-access-management" className={styles.subNavLink}>Privileged Access Management</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/solutions/customer-identity-and-access-management" className={styles.subNavLink}>Customer Identity and Access Management</NavLink>
                    </div>

                    <NavLink onClick={toggleResponsive}   to="/capabilities" className={styles.capabilitiesLink}>
                        Capabilities
                    </NavLink>

                    <div onClick={() => handleAccordionClick("company")} className={`${styles.companyLink} companyLink`}>
                        <div   className={styles.companyText}>Company</div>
                        <div   className={`${styles.companyAccordionSign} companyAccordionSign`}>+</div>
                    </div>
                    <div className={`${styles.companyNavLinks} ${styles.navLinksContainer} companyNavLinksContainer`}>
                        <NavLink onClick={toggleResponsive}   to="/company/about-us" className={styles.subNavLink}>About us</NavLink>
                        <NavLink  onClick={toggleResponsive}  to="/company/why-sharpits" className={styles.subNavLink}>Why Sharp ITS</NavLink>

                        <NavLink  onClick={toggleResponsive} to="/company/our-partners" className={styles.subNavLink}>Partners</NavLink>
                        <NavLink  onClick={toggleResponsive}  to="/company/careers" className={styles.subNavLink}>Careers</NavLink>
                        <NavLink  onClick={toggleResponsive}  to="/company/Privacy" className={styles.subNavLink}>Privacy</NavLink>

                        <NavLink  onClick={toggleResponsive}  to="/company/contact-us" className={styles.subNavLink}>Contact Us</NavLink>
                    </div>

                    <div onClick={() => handleAccordionClick("resources")} className={`${styles.resourcesLink} resourcesLink`}>
                        <div className={styles.resourcesText}>Resources</div>
                        <div className={`${styles.resourcesAccordionSign} resourcesAccordionSign`}>+</div>
                    </div>
                    <div className={`${styles.resourcesNavLinks} ${styles.navLinksContainer} resourcesNavLinksContainer`}>
                        <NavLink onClick={toggleResponsive}   to="/resources/whitepaper" className={styles.subNavLink}>Whitepapers</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/resources/case-studies" className={styles.subNavLink}>Case Studies</NavLink>
                        <NavLink  onClick={toggleResponsive}  to="/resources/blogs" className={styles.subNavLink}>Blogs</NavLink>
                        <NavLink  onClick={toggleResponsive}  to="/resources/brochures" className={styles.subNavLink}>Brochures</NavLink>

                    </div>

                    <NavLink  onClick={toggleResponsive}  to="/contact-us" className={styles.contactLink}>
                        Contact
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Header
